<template>
  <el-dialog
    :visible.sync="dialogShow"
    :width="dialogWidth"
    custom-class="dialog"
    :show-close="false"
    :lock-scroll="true"
    :append-to-body="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :destroy-on-close="true"
    top="0"
  >
    <div class="dialog-main">
      <div class="dialog-header">
        <span>{{ headerText }}</span>
        <div @click="dialogShow = false" class="close-btn d-flex-center">
          <img class="close-img" src="@/assets/images/close.png" alt="" />
        </div>
      </div>
      <slot></slot>
    </div>
  </el-dialog>
</template>

<script>
let winX = null;
let winY = null;
export default {
  props: {
    dialogWidth: {
      type: String,
      default: "700px",
    },
    headerText: String,
  },
  data() {
    return {
      dialogShow: false,
    };
  },
  watch:{
    dialogShow(n){
      if(n){
        this.disableWindowScroll();
      }else{
        this.enableWindowScroll();
      }
    }
  },
  created() {
    window.addEventListener("scroll", function () {
      if (winX !== null && winY !== null) {
        window.scrollTo(winX, winY);
      }
    });
  },
  beforeDestroy(){
    this.enableWindowScroll();
  },
  methods: {
    controlDialog() {
      this.dialogShow = !this.dialogShow;
    },
    disableWindowScroll() {
      winX = window.scrollX;
      winY = window.scrollY;
    },
    enableWindowScroll() {
      winX = null;
      winY = null;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .dialog {
  border-radius: 10px;
  overflow: hidden;
  margin: 0 auto;
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body {
    padding: 0 0px;
  }
  .el-dialog__footer {
    padding: 0;
    padding-bottom: 40px;
  }
}
.el-dialog__wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialog-main {
  width: 100%;
  color: #333;
  .dialog-header {
    width: 100%;
    height: 70px;
    box-sizing: border-box;
    padding-left: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;
    font-weight: bold;
    border-bottom: 1px solid #ccc;
    .close-btn {
      width: 70px;
      height: 70px;
      &:hover {
        cursor: pointer;
      }
      .close-img {
        width: 20px;
        height: 20px;
      }
    }
  }
}
</style>