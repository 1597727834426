<template>
  <div class="common-footer">
    <div class="footer-top">
      <div @click="toPagePlace('home')" class="top-left">
        <img class="logo-img" src="../../assets/images/logo.png" alt="" />
        <span>和慕PMS</span>
      </div>
      <div class="top-right">
        <span @click="toPagePlace('home')">首页</span>
        <span @click="toPagePlace('download')">下载</span>
        <span @click="toPagePlace('price')">定价</span>
        <span @click="$router.push('/businessCooperation')">商务合作</span>
        <span style="margin-right: 0px" @click="$router.push('/business')"
          >集团版</span
        >
        <div v-if="false" class="chat">
          <img
            class="no-click-chat"
            src="../../assets/images/wechat.png"
            alt=""
          />
          <img
            class="click-chat"
            src="../../assets/images/wechat-active.png"
            alt=""
          />
          <div v-if="false" class="code-block">
            <img class="code-img" src="../../assets/images/code.png" alt="" />
            <div class="code-text">扫码关注<br />微信公众号</div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="bottom-left d-flex-align">
        <span>友情链接：</span>
        <a
          target="_blank"
          class="link"
          href="https://www.plcstd.org.cn"
          style="margin-left: 10px"
          >PLCSTD</a
        >
      </div>
      <div class="bottom-right d-flex-align">
        <span>广州和光同行信息科技产业有限公司</span>
        <a
          target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44011202002245"
          style="margin-left: 20px"
          class="police d-flex-center"
        >
          <img
            style="width: 14px; height: 14px"
            src="../../assets/images/police.png"
            alt=""
          />
          <span>粤公网安备 44011202002245号</span>
        </a>
        <a
          href="https://beian.miit.gov.cn/#/Integrated/index"
          target="_blank"
          style="margin-left: 20px"
          >粤ICP备 2022121692号</a
        >
        <span
          class="need-hide"
          @click="showServiceAgreement"
          style="margin-left: 20px"
          >服务协议</span
        >
        <span
          class="need-hide"
          @click="showPrivacyPolicy"
          style="margin-left: 20px"
          >隐私政策</span
        >
      </div>
      <div class="small-middle">
        <a
          target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44011202002245"
          class="police d-flex-center"
        >
          <img
            style="width: 14px; height: 14px"
            src="../../assets/images/police.png"
            alt=""
          />
          <span>粤公网安备 44011202002245号</span>
        </a>
      </div>
      <div class="small-bottom">
        <a
          href="https://beian.miit.gov.cn/#/Integrated/index"
          target="_blank"
          style="margin-right: 20px"
          >粤ICP备 2022121692号</a
        >
        <span @click="showServiceAgreement">服务协议</span>
        <span @click="showPrivacyPolicy" style="margin-left: 20px"
          >隐私政策</span
        >
      </div>
    </div>
    <div @click="toTop" class="footer-btn d-flex-center">
      <img src="../../assets/images/top.png" alt="" />
    </div>
    <ServiceAgreement ref="servieAgreement" />
    <PrivacyPolicy ref="privacyPolicy" />
  </div>
</template>

<script>
import ServiceAgreement from "@/components/ServiceAgreement";
import PrivacyPolicy from "@/components/PrivacyPolicy";
export default {
  data() {
    return {};
  },
  components: {
    ServiceAgreement,
    PrivacyPolicy,
  },
  methods: {
    toPage() {
      window.open("https://www.withlight.cloud", "_blank");
    },
    showServiceAgreement() {
      this.$refs["servieAgreement"].controlDialog();
    },
    showPrivacyPolicy() {
      this.$refs["privacyPolicy"].controlDialog();
    },
    toPagePlace(url) {
      this.$router.push("/");
      let timer = setTimeout(() => {
        clearTimeout(timer);
        let topHeight = 0;
        if (url === "download") {
          topHeight =
            document.getElementById("download").getBoundingClientRect().top +
            document.documentElement.scrollTop;
        } else if (url == "price") {
          topHeight =
            document.getElementById("price").getBoundingClientRect().top +
            document.documentElement.scrollTop;
        }
        this.fnc_scrollto(topHeight);
      }, 100);
    },
    toTop() {
      this.fnc_scrollto(0);
    },
    fnc_scrollto(to, id) {
      var smoothScrollFeature =
        "scrollBehavior" in document.documentElement.style;
      var articles = document.querySelectorAll("ul#content > li");
      if (to == "elem") to = articles[id].offsetTop;
      var i = parseInt(window.pageYOffset);
      if (i != to) {
        if (!smoothScrollFeature) {
          to = parseInt(to);
          if (i < to) {
            let int = setInterval(function () {
              if (i > to - 20) i += 1;
              else if (i > to - 40) i += 3;
              else if (i > to - 80) i += 8;
              else if (i > to - 160) i += 18;
              else if (i > to - 200) i += 24;
              else if (i > to - 300) i += 40;
              else i += 60;
              window.scroll(0, i);
              if (i >= to) clearInterval(int);
            }, 15);
          } else {
            let int = setInterval(function () {
              if (i < to + 20) i -= 1;
              else if (i < to + 40) i -= 3;
              else if (i < to + 80) i -= 8;
              else if (i < to + 160) i -= 18;
              else if (i < to + 200) i -= 24;
              else if (i < to + 300) i -= 40;
              else i -= 60;
              window.scroll(0, i);
              if (i <= to) clearInterval(int);
            }, 15);
          }
        } else {
          window.scroll({
            top: to,
            left: 0,
            behavior: "smooth",
          });
        }
      }
    },
  },
};
</script>

<style scoped lang='scss'>
.common-footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 260px;
  background: #111111;
  padding: 0 11%;
  padding-top: 80px;
  padding-bottom: 25px;
  box-sizing: border-box;
  position: relative;
  @media screen and (max-width: 1299px) {
    padding-left: 62px;
    padding-right: 62px;
    height: 280px;
  }
  @media screen and (max-width: 1000px) {
    padding-top: 40px;
    height: 320px;
  }
  @media screen and (max-width: 749px) {
    padding-top: 40px;
    padding-left: 20px;
    padding-right: 20px;
    height: 360px;
  }
  .footer-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 1000px) {
      flex-direction: column;
      align-items: flex-start;
    }
    .top-left {
      display: flex;
      align-items: center;
      &:hover {
        cursor: pointer;
      }
      @media screen and (max-width: 1000px) {
        margin-bottom: 50px;
      }
      .logo-img {
        width: 40px;
        height: 40px;
        margin-right: 15px;
      }
      span {
        color: #fff;
        font-size: 20px;
      }
    }
    .top-right {
      display: flex;
      align-items: center;
      white-space: nowrap;
      flex-wrap: wrap;
      line-height: 36px;
      span {
        font-size: 16px;
        color: #fff;
        margin-right: 45px;
        @media screen and (max-width: 749px) {
          margin-right: 30px;
        }
        &:hover {
          cursor: pointer;
        }
      }
      .chat {
        position: relative;
        margin-left: 70px;
        @media screen and (max-width: 749px) {
          position: absolute;
          right: 20px;
          top: 40px;
        }
        &:hover {
          cursor: pointer;
          .no-click-chat {
            display: none;
          }
          .click-chat {
            display: block;
          }
          .code-block {
            display: flex;
          }
        }
        img {
          width: 32px;
          height: 32px;
        }
        .no-click-chat {
          display: block;
        }
        .click-chat {
          display: none;
        }
        .code-block {
          position: absolute;
          bottom: 46px;
          left: 50%;
          transform: translateX(-50%);
          width: 130px;
          height: 180px;
          background: rgba(17, 17, 17, 1);
          border: 1px solid #707070;
          opacity: 1;
          border-radius: 10px;
          display: none;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          @media screen and (max-width: 749px) {
            left: 0;
            transform: translateX(-100%);
          }
          .code-img {
            width: 100px;
            height: 100px;
          }
          .code-text {
            font-size: 13px;
            color: #fff;
            text-align: center;
            line-height: 20px;
            margin-top: 11.5px;
          }
        }
      }
    }
  }

  .footer-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #999;
    font-size: 14px;
    @media screen and (max-width: 1299px) {
      flex-direction: column;
      align-items: flex-start;
      .bottom-left {
        margin-bottom: 20px;
      }
    }
    @media screen and (max-width: 1000px) {
      justify-content: center;
      .bottom-left {
        margin-bottom: 20px;
      }
    }
    a {
      color: #999;
    }
    .police {
      &:hover {
        cursor: pointer;
      }
    }
    .bottom-right {
      .need-hide {
        display: block;
        &:hover {
          cursor: pointer;
        }
        @media screen and (max-width: 1000px) {
          display: none;
        }
      }
      a {
        @media screen and (max-width: 749px) {
          display: none;
        }
      }
    }
    .small-middle {
      display: none;
      align-items: center;
      margin-top: 20px;
      @media screen and (max-width: 749px) {
        display: flex;
      }
    }

    .small-bottom {
      display: none;
      margin-top: 20px;
      a {
        display: none;
        @media screen and (max-width: 749px) {
          display: inline-block;
        }
      }
      span {
        &:hover {
          cursor: pointer;
        }
      }
      @media screen and (max-width: 1000px) {
        display: block;
      }
    }
  }
  .footer-btn {
    position: fixed;
    right: 40px;
    bottom: 40px;
    width: 44px;
    height: 44px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 3px 6px rgba(47, 124, 191, 0.16);
    border-radius: 50%;
    z-index: 9;
    @media screen and (max-width: 749px) {
      right: 20px;
    }
    &:hover {
      cursor: pointer;
    }
    img {
      width: 44px;
      height: 44px;
    }
  }
}
</style>