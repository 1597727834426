<template>
  <WebDialog ref="webDialog" :dialogWidth="dialogWidth" :headerText="headerText">
    <iframe class="iframe-content" src="html/privacy-policy.html" frameborder="0"></iframe>
  </WebDialog>
</template>

<script>
import WebDialog from "@/components/Webdialog";
export default {
  props:{
    headerText:{
      type:String,
      default:'隐私政策'
    }
  },
  data() {
    return {
      dialogShow: false,
      dialogWidth:'800px'
    };
  },
  components: {
    WebDialog,
  },
  created(){
    window.addEventListener('resize',this.windowResize);
  },
  beforeDestroy(){
    window.removeEventListener('resize',this.windowResize);
  },
  methods: {
    windowResize(){
      if(window.innerWidth <= 1000 && window.innerWidth >=750){
        this.dialogWidth = '660px';
      }else if(window.innerWidth > 1000){
        this.dialogWidth = '800px';
      }else{
        this.dialogWidth = window.innerWidth - 40 + 'px';
      }
    },
    // 控制dialog的显示和隐藏
    controlDialog() {
      this.$refs["webDialog"].controlDialog();
      if(window.innerWidth <= 1000 && window.innerWidth >=750){
        this.dialogWidth = '660px';
      }else if(window.innerWidth > 1000){
        this.dialogWidth = '800px';
      }else{
        this.dialogWidth = window.innerWidth - 40 + 'px';
      }
    },
  },
};
</script>

<style scoped lang='scss'>
.iframe-content {
  width: 100%;
  height: calc(100vh - 250px);
}
</style>